<template>
    <v-fab-transition>
        <v-btn
                v-show="fab"
                dark
                fixed
                color="white"
                @click="toTop"
                class="custom-button-andrey pa-0"
                :style="{
                  bottom: `${bottom}px`,
                  right: `${right}px`,
                  zIndex: 4,
                }"
        >
            <v-icon large color="primary">keyboard_arrow_up</v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
  export default {
    props: {
      el: {
        type: String,
        default() {
          return '';
        }
      },
      fab: {
        type: Boolean,
        default() {
          return false;
        }
      },
      bottom: {
        type: Number,
        default() {
          return 120;
        }
      },
      right: {
        type: Number,
        default() {
          return 20;
        }
      },
    },
    data: () => ({
    }),

    computed: {
    },

    methods: {
      toTop () {
        this.$vuetify.goTo(0, { container: `#${this.el}` })
      }
    }
  }
</script>
