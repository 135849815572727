export default axios => ({
  // LANDING PAGES
  postLandingPage(params) {
    return axios.post('/admin/landing-pages', params)
  },
  putLandingPage(params) {
    return axios.post(`/admin/landing-pages/${params.get('id')}`, params)
  },
  deleteLandingPage(params) {
    return axios.delete(`/admin/landing-pages/${params.id}`)
  },
  readyTemplate(params) {
    return axios.post('/admin/landing-pages/template-ready', params)
  },
  cloneTemplate(params) {
    return axios.post('/admin/landing-pages/clone', params)
  },
  // -------------------------

  // SITES
  getSites(params) {
    return axios.get('/admin/sites', {params})
  },
  postSite(params) {
    return axios.post('/admin/sites', params)
  },
  putSite(params) {
    return axios.post(`/admin/sites/${params.get('id')}`, params)
  },
  deleteSite(params) {
    return axios.delete(`/admin/sites/${params.id}`)
  },
  readySite(params) {
    return axios.post('/admin/site-ready', params)
  },
  // -------------------------

  // USERS
  getUsers(params) {
    return axios.get('/admin/users', {params})
  },
  getUser(params) {
    return axios.get(`/admin/users/${params.id}`, {params})
  },
  postUser(params) {
    return axios.post('/admin/users', params)
  },
  putUser(params) {
    return axios.put(`/admin/users/${params.id}`, params)
  },
  deleteUser(params) {
    return axios.delete(`/admin/users/${params.id}`)
  },

  setLoginAsUser(params) {
    return axios.post(`/admin/login-as-user/${params.id}`)
  },
  // -------------------------

  // TAGS
  postTag(params) {
    return axios.post('/admin/tags', params)
  },
  putTag(params) {
    return axios.put(`/admin/tags/${params.id}`, params)
  },
  deleteTag(params) {
    return axios.delete(`/admin/tags/${params.id}`)
  },

  attachTags(params) {
    return axios.post('/admin/tags/attach-tags', params)
  },
  // -------------------------

  // DEPLOYS
  getDeploys(params) {
    return axios.get('/admin/deploys', {params})
  },
  getDeploy(params) {
    return axios.get(`/admin/deploys/${params.id}`)
  },
  postDeploy(params) {
    return axios.post('/admin/deploys', params)
  },
  putDeploy(params) {
    return axios.put(`/admin/deploys/${params.id}`, params)
  },
  deleteDeploy(params) {
    return axios.delete(`/admin/deploys/${params.id}`)
  },
  pushToGit(params) {
    return axios.post(`/admin/deploys/${params.id}/push-to-git`, params)
  },
  postCloneDeploy(params) {
    return axios.post('/admin/deploys/clone', params)
  },
  postRemoveFromGitDeploy(params) {
    return axios.post(`admin/deploys/${params.id}/remove-from-git`)
  },
  // -------------------------

  // CUSTOM PAGES
  postCustomPage(params) {
    return axios.post('/admin/custom-pages', params)
  },
  putCustomPage(params) {
    return axios.post(`/admin/custom-pages/${params.get('id')}`, params)
  },
  deleteCustomPage(params) {
    return axios.delete(`/admin/custom-pages/${params.id}`)
  },
  pushToGitCustomPage(params) {
    return axios.post(`/admin/custom-pages/${params.id}/push-to-git`, params)
  },
  postCloneCustomPage(params) {
    return axios.post('/admin/custom-pages/clone', params)
  },
  postRemoveFromGitCustomPage(params) {
    return axios.post(`/admin/custom-pages/${params.id}/remove-from-git`)
  },
  // -------------------------

  // GITHAB
  getRepos(params) {
    return axios.get('/admin/github/repositories', {params})
  },
  postRepo(params) {
    return axios.post('/admin/github/create-repository', params)
  },
  postSetSecrets(params) {
    return axios.post(`/admin/github/set-secrets`, params)
  },
  getSecrets(params) {
    return axios.get(`/admin/github/get-secrets/${params.name}`)
  },
  // -------------------------

  // LOGS
  getLogsConversions(params) {
    return axios.get('/admin/logs/tune-log-table', {params})
  },
  exportCSVConversions(params) {
    return axios.get('/admin/logs/tune-log/export/csv', {params, responseType: 'blob'})
  },
  // -------------------------
});
