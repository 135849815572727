export default {
  state: {
    myScriptsAll: [],
    myURLsAll: [],
    myVariablesAll: [],
    myQuizQuestionsAll: [],
  },
  actions: {
    async getCustomCodes (state, params) {
      return await this._vm.$api.customCodes.getCustomCodes(params)
    },
    async postCustomCode (state, params) {
      return await this._vm.$api.customCodes.postCustomCode(params)
    },
    async putCustomCode (state, params) {
      return await this._vm.$api.customCodes.putCustomCode(params)
    },
    async deleteCustomCode (state, params) {
      return await this._vm.$api.customCodes.deleteCustomCode(params)
    },
    async postCloneCustomCode (state, params) {
      return await this._vm.$api.customCodes.postCloneCustomCode(params)
    },
    async postQuizCustomCode (state, params) {
      return await this._vm.$api.customCodes.postQuizCustomCode(params)
    },
    async putQuizCustomCode (state, params) {
      return await this._vm.$api.customCodes.putQuizCustomCode(params)
    },

    async getCustomCodesAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit'
        };

        const res = await this._vm.$api.customCodes.getCustomCodes(params);

        let data = res.data.data ? res.data.data : res.data;
        let myScripts = [],
            myURLs = [],
            myVariables = [],
            myQuizQuestions = [];
        data.forEach(a => {
          if (a.type === 'code') {
            myScripts.push(a)
          } else if (a.type === 'url') {
            myURLs.push(a)
          } else if (a.type === 'variable') {
            myVariables.push(a)
          } else if (a.type === 'quiz') {
            myQuizQuestions.push(a)
          }
        })

        commit('SET_MY_SCRIPTS_ALL', myScripts);
        commit('SET_MY_URLS_ALL', myURLs);
        commit('SET_MY_VARIABLES_ALL', myVariables);
        commit('SET_MY_QUIZ_QUESTIONS_ALL', myQuizQuestions);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_MY_SCRIPTS_ALL (state, data) {
      state.myScriptsAll = data;
    },
    SET_MY_URLS_ALL (state, data) {
      state.myURLsAll = data;
    },
    SET_MY_VARIABLES_ALL (state, data) {
      state.myVariablesAll = data;
    },
    SET_MY_QUIZ_QUESTIONS_ALL (state, data) {
      state.myQuizQuestionsAll = data;
    },
  },
  getters: {
  },
};