<style lang="scss">
    @import 'styles.scss';
</style>

<template>
  <div class="text-decoration-component">
    <v-toolbar dense elevation="0">
      <v-btn-toggle
          :value="weight"
          color="primary"
          dense
          group
          @change="changeTextWeight"
      >
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'800'" :disabled="disabledProp">
              <v-icon style="font-weight:900">format_bold</v-icon>
            </v-btn>
          </template>
          <span>
            Extra bold
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'700'" :disabled="disabledProp">
              <v-icon>format_bold</v-icon>
            </v-btn>
          </template>
          <span>
            Bold
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'300'" :disabled="disabledProp">
              <v-icon>$thinTextSvg</v-icon>
            </v-btn>
          </template>
          <span>
            Thin
          </span>
        </v-tooltip>
      </v-btn-toggle>
      <v-btn-toggle
            :value="textStylesComputed"
            color="primary"
            dense
            group
            multiple
            @change="changeTextStyles"
        >
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'italic'" :disabled="disabledProp">
              <v-icon>format_italic</v-icon>
            </v-btn>
          </template>
          <span>
            Italic
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'line-through'" :disabled="disabledProp">
              <v-icon>format_strikethrough</v-icon>
            </v-btn>
          </template>
          <span>
            Strikethrough
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'underline'" :disabled="disabledProp">
              <v-icon>format_underline</v-icon>
            </v-btn>
          </template>
          <span>
            Underline
          </span>
        </v-tooltip>
      </v-btn-toggle>

      <v-spacer></v-spacer>

      <v-item-group
          class="v-btn-toggle v-btn-toggle--dense v-btn-toggle--group primary--text"
          color="primary"
          dense
          group
      >
        <v-menu top eager
                :close-on-content-click="false"
                :ref="`colors_${valueName}_menu`"
                @input="colorOpenMenu"
                :disabled="disabledProp"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip eager top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn  v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        :disabled="disabledProp"
                >
                  <v-row
                      align="center"
                      class="flex-column"
                      justify="center"
                  >
                    <v-icon class="cols 12">format_color_text</v-icon>
                    <v-sheet
                        tile
                        class="color-line"
                        height="4"
                        width="26"
                        :color="color"
                    ></v-sheet>
                  </v-row>

                </v-btn>
              </template>
              <span>
                Text color
              </span>
            </v-tooltip>

          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker :ref="`colors_${valueName}`"
                              :value="color"
                              @input="changeTextColor"
                              mode="hexa"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn small
                     color="primary"
                     @click="colorToDefault(`colors_${valueName}_menu`)"
              >
                to default
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small
                     @click="colorClose(`colors_${valueName}_menu`)"
              >
                cancel
              </v-btn>
              <v-btn small
                     color="success"
                     @click="colorSave(`colors_${valueName}`, `colors_${valueName}_menu`)"
              >
                ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-item-group>

      <v-spacer v-if="textAlignProp"></v-spacer>

      <v-btn-toggle v-if="textAlignProp"
          :value="position"
          color="primary"
          dense
          group
          mandatory
          @change="changeTextAlign"
      >
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'start'" :disabled="disabledProp">
              <v-icon>format_align_left</v-icon>
            </v-btn>
          </template>
          <span>
            Align left
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'center'" :disabled="disabledProp">
              <v-icon>format_align_center</v-icon>
            </v-btn>
          </template>
          <span>
            Align center
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'end'" :disabled="disabledProp">
              <v-icon>format_align_right</v-icon>
            </v-btn>
          </template>
          <span>
            Align right
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'justify'" :disabled="disabledProp">
              <v-icon>format_align_justify</v-icon>
            </v-btn>
          </template>
          <span>
            Align justify
          </span>
        </v-tooltip>
      </v-btn-toggle>
    </v-toolbar>
  </div>
</template>

<script>

export default {
  props: {
    valueName: {
      type: String,
      default() {
        return '';
      }
    },
    weight: {
      type: String,
      default() {
        return '400';
      }
    },
    textStyles: {
      type: String,
      default() {
        return '';
      }
    },
    color: {
      type: String,
      default() {
        return '#000000FF';
      }
    },
    defaultColor: {
      type: String,
      default() {
        return '#000000FF';
      }
    },
    position: {
      type: String,
      default() {
        return 'center';
      }
    },
    disabledProp: {
      type: Boolean,
      default() {
        return false;
      }
    },
    textAlignProp: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data: () => ({
    changedColor: null,
  }),
  computed: {
    textStylesComputed() {
      let arr = [];
      if (this.textStyles.includes('italic')) arr.push('italic');
      if (this.textStyles.includes('line-through')) arr.push('line-through');
      if (this.textStyles.includes('underline')) arr.push('underline');
      return arr
    }
  },
  watch: {
  },
  created() {
  },
  mounted() {
  },
  methods: {
    changeTextAlign(value) {
      this.$emit('changeTextAlign', value);
    },
    changeTextWeight(value) {
      let val = '400';
      if (value) val = value;
      this.$emit('changeTextWeight', val);
    },
    changeTextStyles(value) {
      let val = '';
      let valDec = '';
      value.forEach(v => {
        if (v === 'italic') {
          val = val + `font-style: ${v};`
        } else {
          valDec = valDec + ' ' + v
        }
      })
      if (valDec) valDec = `text-decoration: ${valDec};`
      val = val + valDec;
      this.$emit('changeTextStyles', val);
    },
    changeTextColor(value) {
      this.$emit('changeTextColor', value);
    },
    colorSave(ref, refMenu) {
      if (this.$refs[ref]) {
        if (this.$refs[refMenu]) this.colorClose(refMenu, true);
      }
    },
    colorToDefault(refMenu) {
      if (this.color) {
        this.changeTextColor(this.defaultColor);
      }
      if (this.$refs[refMenu]) this.colorClose(refMenu, true);
    },
    colorClose(refMenu, save = false) {
      if (save) {
        this.changedColor = null;
        this.$refs[refMenu].save();
      } else {
        this.$refs[refMenu].save();
      }
    },
    colorOpenMenu(val) {
      if (val) {
        if (this.changedColor !== this.color) this.changedColor = this.color;
      } else {
        if (this.changedColor !== null) {
          this.changeTextColor(this.changedColor);
          this.changedColor = null;
        }
      }
    },
  },
}
</script>
