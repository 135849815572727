export default {
  state: {
    allReports: {},
    datesReport: {
      from: '',
      to: ''
    },
    tzReport: 'EST'
  },
  actions: {
    async getReportRevenue ({ commit }, params) {
      return new Promise((resolve, reject) => {
        this._vm.$api.tuneRevenue.getReportRevenue(params)
          .then(res => {
            commit('SET_REPORT_REVENUE', {data: res.data.data ? res.data.data : res.data, datesReport: params});
            resolve();
          })
          .catch(error => reject(error))
      })
    },
    async exportArchiveRevenue (state, params) {
      return await this._vm.$api.tuneRevenue.exportArchiveRevenue(params)
    },
  },
  mutations: {
    SET_REPORT_REVENUE(state, data) {
      const from = this._vm.$moment(data.datesReport.from).format('YYYY-MM-DD');
      const to = this._vm.$moment(data.datesReport.to).format('YYYY-MM-DD');

      let allReports = data.data;

      if (allReports?.by_campaign) {
        allReports.by_campaign.forEach(campaign => campaign.date = `${this._vm.$moment(from).local().format('MMMM D YYYY')} ~ ${this._vm.$moment(to).local().format('MMMM D YYYY')}`)
      }

      state.allReports = allReports;
      // state.allReports = data.data;
      state.datesReport = {from, to};
      state.tzReport = data.datesReport.timezone;
    }
  },
  getters: {
  },
};