export default axios => ({
  postCustomPage(params) {
    return axios.post('/custom-pages', params)
  },
  putCustomPage(params) {
    return axios.post(`/custom-pages/${params.get('id')}`, params)
  },
  deleteCustomPage(params) {
    return axios.delete(`/custom-pages/${params.id}`)
  },
  pushToGitCustomPage(params) {
    return axios.post(`/custom-pages/${params.id}/push-to-git`, params)
  },
  postCloneCustomPage(params) {
    return axios.post('/custom-pages/clone', params)
  },
  postRemoveFromGitCustomPage(params) {
    return axios.post(`/custom-pages/${params.id}/remove-from-git`)
  },
});