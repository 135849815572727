import axios from 'axios';
import store from '@/store';

export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_ENTERS_HASH_HERE}}',
      hashChanged: false,
      newHash: ''
    }
  },
  methods: {
    initVersionCheck(url, frequency = 1000 * 60 * 1) {
      setInterval(() => {
        this.checkVersion(url);
      }, frequency);
    },
    checkVersion(url) {
      if (process.env.NODE_ENV !== 'development') {
        axios.get(`${url}?t=${new Date().getTime()}`)
          .then(res => {
            this.newHash = res.data.hash;
            this.hashChanged = this.hasHashChanged(this.currentHash, this.newHash);
          })
          .catch(error => store.commit('SET_SNACK', {text: error.data || '', type: 'error'}));
      }
    },
    hasHashChanged(currentHash, newHash) {
      if (!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}') {
        return true;
      }
      return currentHash !== newHash;
    },
    reloadApp() {
      this.currentHash = this.newHash;
      window.location.reload(true);
    }
  }
};