export default {
  state: {
  },
  actions: {
    async getCampaigns (state, params) {
      return await this._vm.$api.campaigns.getCampaigns(params)
    },
    async reSyncCampaigns ({commit}, params) {
      try {
        await this._vm.$api.campaigns.reSyncCampaigns(params);

        commit('SET_SNACK', {text: 'Campaigns list successfully re-synced', type: 'success'});
      }
      catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async toggleSyncCampaigns ({commit}, params) {
      try {
        await this._vm.$api.campaigns.toggleSyncCampaigns(params);

        commit('SET_SNACK', {text: `Sync successfully ${params.auto_sync === true ? 'stopped' : 'started'}`, type: 'success'});
      }
      catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async forceSyncCampaigns ({commit}, params) {
      try {
        await this._vm.$api.campaigns.forceSyncCampaigns(params);

        commit('SET_SNACK', {text: 'Forced sync succeeded', type: 'success'});
      }
      catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async addSpreadsheetCampaigns ({commit}, params) {
      try {
        await this._vm.$api.campaigns.addSpreadsheetCampaigns(params);

        commit('SET_SNACK', {text: 'Sheet added successfully', type: 'success'});
      }
      catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async deleteSpreadsheetCampaigns ({commit}, params) {
      try {
        await this._vm.$api.campaigns.deleteSpreadsheetCampaigns(params);

        commit('SET_SNACK', {text: 'Sheet deleted successfully', type: 'success'});
      }
      catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async saveSettingsCampaigns (state, params) {
      return await this._vm.$api.campaigns.saveSettingsCampaigns(params);
    },
  },
  mutations: {
  },
  getters: {
  },
};