import axios from 'axios';
import Vue from 'vue';
import store from '@/store';
import broadcastChannel from '@/plugins/broadcastChannel';
import Cookies from 'js-cookie';

import Campaigns from "@/api/campaigns";
import User from "@/api/user";
import LandingPages from "@/api/landingPages";
import CustomCodes from "@/api/customCodes";
import Deploys from "@/api/deploys";
import CustomPages from "@/api/customPages";
import Sites from "@/api/sites";
import Translations from "@/api/translations";
import Tags from "@/api/tags";
import Admin from "@/api/admin";
import TuneRevenue from "@/api/tuneRevenue";

let $axios = {};

const ajaxUrl = process.env.VUE_APP_URL_API;

$axios.ajax = axios.create({
  baseURL: ajaxUrl,
  timeout: 60000
});

$axios.ajax.interceptors.request.use(config => {
  const token = Cookies.get('RTT');
  if (token) config.headers.Authorization = `Bearer ${token}`;
  return config;
}, error => {
  return Promise.reject(error);
});

let isAlreadyFetchingAccessToken = false;
let isAlreadyRefreshTokenTabs = false;
let isDuplicateLider = false;
let duplicateLiders = [];
let subscribers = [];

$axios.setIsAlreadyFetchingAccessToken = function (data) {
  isAlreadyFetchingAccessToken = data
}

$axios.setSubscribers = function (data) {
  subscribers = data
}

$axios.onAccessTokenFetched = function (access_token) {
  subscribers = subscribers.filter(callback => callback(access_token))
}

$axios.addSubscriber = function (callback) {
  subscribers.push(callback)
}

$axios.checkToken = async function () {
  try {
    const rt = localStorage.getItem('RRTT');
    const token = Cookies.get('RTT');

    if (token && rt) {
      let res = await store.dispatch('refreshToken', {refresh_token: rt});

      await broadcastChannel.channel.postMessage({type: 'login', payload: res.data});
    } else {
      return Promise.reject('Unauthenticated');
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

$axios.refreshTokenTabs = async function () {
  if (broadcastChannel.elector && !broadcastChannel.elector.hasLeader) {
    await broadcastChannel.elector.awaitLeadership()
    if (broadcastChannel.elector.isLeader) {
      store.commit('SET_LEADER_TAB', true);
    }
  }
  let leader;
  if (isDuplicateLider) {
    leader = duplicateLiders.sort()[0] === broadcastChannel.elector.token;
  } else {
    leader = broadcastChannel.elector.isLeader;
  }
  if (leader && !isAlreadyRefreshTokenTabs) {
    isAlreadyRefreshTokenTabs = true;
    $axios.checkToken()
      .then(() => {
        isAlreadyFetchingAccessToken = false;
        broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
        const token = Cookies.get('RTT');
        $axios.onAccessTokenFetched(token);
        broadcastChannel.channel.postMessage({type: 'onAccessTokenFetched', payload: token});
        setTimeout(() => isAlreadyRefreshTokenTabs = false, 5000);
      })
      .catch(error => {
        if (!isDuplicateLider || (isDuplicateLider && duplicateLiders.sort()[0] === broadcastChannel.elector.token)) {
          store.dispatch('logout');
          broadcastChannel.channel.postMessage({type: 'logout'});
          store.commit('SET_SNACK', {text: error, type: 'error'});
          isAlreadyFetchingAccessToken = false;
          subscribers = [];
          isAlreadyRefreshTokenTabs = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
          broadcastChannel.channel.postMessage({type: 'setSubscribers', payload: []});
        } else {
          isAlreadyFetchingAccessToken = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
          const token = Cookies.get('RTT');
          $axios.onAccessTokenFetched(token);
          broadcastChannel.channel.postMessage({type: 'onAccessTokenFetched', payload: token});
          setTimeout(() => isAlreadyRefreshTokenTabs = false, 5000);
        }
      })
      .finally(() => {
        setTimeout(() => {
          isAlreadyFetchingAccessToken = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
        }, 10000)
        setTimeout(() => {
          isAlreadyFetchingAccessToken = false;
          broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
        }, 20000)
      })
  }
}

$axios.ajax.interceptors.response.use(response => {
  return response;
}, error => {
  const { config, response: { status } } = error;
  const originalRequest = config;

  if (originalRequest.url.includes('jwt/refresh') && status === 401) {
    if (!isDuplicateLider || (isDuplicateLider && duplicateLiders.sort()[0] === broadcastChannel.elector.token)) {
      store.dispatch('logout');

      broadcastChannel.channel.postMessage({type: 'logout'});
      store.commit('SET_SNACK', {text: 'Unauthenticated', type: 'error'});
      isAlreadyFetchingAccessToken = false;
      subscribers = [];
      broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: false});
      broadcastChannel.channel.postMessage({type: 'setSubscribers', payload: []});
    }
    return Promise.reject({data: {message: 'Token expired'}});
  }

  if (status === 401) {
    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;
      broadcastChannel.channel.postMessage({type: 'setIsAlreadyFetchingAccessToken', payload: true});
      broadcastChannel.channel.postMessage({type: 'refreshTokenTabs'});
      $axios.refreshTokenTabs()
    }
    return new Promise((resolve) => {
      $axios.addSubscriber(access_token => {
        originalRequest.headers.Authorization = 'Bearer ' + access_token;
        resolve(axios(originalRequest))
      })
    })
  }

  if (error.response.status === 402 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }
  if (error.response.status === 403 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }
  if (error.response.status === 404 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }
  if (error.response.status === 500 && !error.config._retry) {
    error.config._retry = true;

    return Promise.reject(error.response ? error.response : error);
  }

  return Promise.reject(error.response ? error.response : error);
});

const factories = {
  campaigns: Campaigns($axios.ajax),
  user: User($axios.ajax),
  landingPages: LandingPages($axios.ajax),
  customCodes: CustomCodes($axios.ajax),
  deploys: Deploys($axios.ajax),
  customPages: CustomPages($axios.ajax),
  sites: Sites($axios.ajax),
  translations: Translations($axios.ajax),
  tags: Tags($axios.ajax),
  admin: Admin($axios.ajax),
  tuneRevenue: TuneRevenue($axios.ajax),
};

Vue.prototype.$axios = $axios.ajax;
Vue.prototype.$api = factories;

$axios.checkUpdate = function () {
};

export default $axios;
