export default axios => ({
  setLogin(data) {
    return axios.post(`/login`, data)
  },
  setRegister(data) {
    return axios.post(`/register`, data)
  },
  setMe() {
    return axios.get(`/me`)
  },
  refreshToken(data) {
    return axios.post(`/jwt/refresh`, data)
  }
});