import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import $axios from '@/plugins/axios'
import broadcastChannel from '@/plugins/broadcastChannel'
import moment from '@/plugins/moment'
import store from '@/store'
import {router} from './router'
import linkify from 'vue-linkify'
import slugify from 'slugify'
import _ from 'lodash';

import VueCodemirror from 'vue-codemirror'

import ScrollTopElement from '@/components/scroll-top-element'
import Snackbar from '@/components/snack'
import SnackbarUpdate from '@/components/snack-update'
import Confirm from '@/components/confirm'
import MarkdownTextarea from '@/components/markdown-textarea'
import TextDecoration from '@/components/text-decoration'

Vue.prototype.$moment = moment;
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$slugify = slugify;
Vue.prototype.$_ = _;

const markdownItAttrs = require('markdown-it-attrs');
const markdownItReplacements = require('markdown-it-replace-it');
markdownItReplacements.replacements.push({
      name: 'extraBoldStart',
      re: /@>/gm,
      sub: () => { return `<b class="custom-template-text-extra-bold">` },
      html: true,
      default: true
    },
    {
      name: 'extraBoldEnd',
      re: /<@/gm,
      sub: () => { return `</b>` },
      html: true,
      default: true
    },{
      name: 'thinStart',
      re: /&>/gm,
      sub: () => { return `<b class="custom-template-text-thin">` },
      html: true,
      default: true
    },
    {
      name: 'thinEnd',
      re: /<&/gm,
      sub: () => { return `</b>` },
      html: true,
      default: true
    },{
      name: 'underlineStart',
      re: /\^>/gm,
      sub: () => { return `<u>` },
      html: true,
      default: true
    },
    {
      name: 'underlineEnd',
      re: /<\^/gm,
      sub: () => { return `</u>` },
      html: true,
      default: true
    },
    {
      name: 'colorStart',
      re: /#color#[a-fA-F0-9]{3,8}>/gm,
      sub: s => {
        const color = s.replace('#color','').replace('>','');
        return `<span style="color:${color};text-decoration-color:${color}">`
      },
      html: true,
      default: true
    },
    {
      name: 'colorEnd',
      re: /<color#[a-fA-F0-9]{3,8}#/gm,
      sub: () => { return `</span>` },
      html: true,
      default: true
    });

Vue.prototype.$md = require('markdown-it')()
    .use(markdownItAttrs, {
      leftDelimiter: '{',
      rightDelimiter: '}',
      allowedAttributes: []
    })
    .use(markdownItReplacements);

Vue.config.productionTip = false;

Vue.component('ScrollTopElement', ScrollTopElement);
Vue.component('Snackbar', Snackbar);
Vue.component('SnackbarUpdate', SnackbarUpdate);
Vue.component('Confirm', Confirm);
Vue.component('MarkdownTextarea', MarkdownTextarea);
Vue.component('TextDecoration', TextDecoration);

Vue.use(VueCodemirror);

Vue.directive('linkified', linkify);

window.$ = window.jQuery = window.jquery = require("jquery");

new Vue({
  vuetify,
  router,
  store,
  render: h => h(App),
  mounted() {
    $axios.checkUpdate(this);
    broadcastChannel.checkUpdate(this);
  }
}).$mount('#app')
