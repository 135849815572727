<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <v-footer elevation="12" color="primary" height="40" class="white--text px-8 footer-app" app>
    <span class="mr-12"></span>
    <v-spacer></v-spacer>
    <span class="subtitle-2 font-weight-regular">&copy; {{ $moment().format('YYYY') }} {{ title }}
      <span>
        a division of
        <a target="_blank" class="white--text font-weight-medium" href="https://www.thinkbasis.com">
          Think Basis Inc.
        </a>
      </span>
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({}),
  computed: {
    title() {
      return process.env.VUE_APP_TITLE
    },
  },
  methods: {
  }
}
</script>