import Vue from 'vue'
import Router from 'vue-router'
import {routers} from './routers';
import Cookies from 'js-cookie';

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routers
});

const getRouterObjByName = function (routers, name) {
// role === 1 - Admin
// role === 2 - User
// role === 3 - Affiliate

  if (!name || !routers || !routers.length) {
    return null;
  }

  let routerObj = null;
  for (let item of routers) {
    if (item.name === name) {
      const role = router?.app?.$store?.state?.user?.user?.role;
      if (role === 'admin') {
        item.role = 1;
      } else if (role === 'user') {
        item.role = 2;
      } else if (role === 'affiliate') {
        item.role = 3;
      } else {
        item.role = 100;
      }
      return item;
    }
    routerObj = getRouterObjByName(item.children, name);
    if (routerObj) {
      return routerObj;
    }
  }
  return null;
};

const getRole = function () {
  return new Promise((resolve) => {
    let _timer1 = setInterval(() => {
      if (router.app?.$store?.state?.user?.user?.role) {
        clearInterval(_timer1);
        resolve(router.app.$store.state.user.user.role)
      }
    }, 300)
  })
};

router.beforeEach(async (to, from, next) => {
  if (Cookies.get('RTT') && !router.app?.$store?.state?.user?.user?.role) {
    await getRole();
  }

  const curRouterObj = getRouterObjByName(routers, to.name);

  setTimeout(() => router.app.$store.commit('SET_SHOW_PROGRESS_SHOW'));

  if (!Cookies.get('RTT') && to.name !== 'login' && to.name !== 'signup') {
    setTimeout(() => {
      router.app.$store.dispatch('logout');
    });
    next({
      name: 'login'
    });
  } else if (Cookies.get('RTT') && (to.name === 'login' || to.name === 'signup')) {
    next({
      name: 'main'
    });
  } else if (!curRouterObj) {
    next({
      replace: true,
      name: 'error-404'
    });
  } else if (curRouterObj.access && curRouterObj.access < curRouterObj.role) {
    next({
      replace: true,
      name: 'error-403'
    });
  } else {
    next()
  }
});

router.afterEach(() => {
  setTimeout(() => router.app.$store.commit('SET_SHOW_PROGRESS_HIDE'), 1000);
});
