<style lang="scss">
    @import 'styles.scss';
</style>

<template>
    <v-fab-transition>
        <v-btn
                v-show="fab"
                v-scroll="onScroll"
                dark
                fixed
                color="white"
                @click="toTop"
                class="custom-button-andrey pa-0 v-btn-scroll-top"
        >
            <v-icon large color="primary">keyboard_arrow_up</v-icon>
        </v-btn>
    </v-fab-transition>
</template>

<script>
  export default {
    data: () => ({
      fab: false
    }),

    computed: {
    },

    methods: {
      onScroll () {
        if (typeof window === 'undefined') return;
        const top = window.pageYOffset || document.documentElement.offsetTop || 0;
        this.fab = top > 200
      },
      toTop () {
        this.$vuetify.goTo(0)
      }
    }
  }
</script>
