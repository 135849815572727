<template>
  <v-fade-transition hide-on-leave>
    <v-main id="main-auth-div" v-show="!preloaderApp && loadPage">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-sheet class="mx-auto"
                   width="500"
                   tile
          >
            <div class="mb-8">
              <v-layout align-center justify-center>
                <v-img
                    src="/logo.png"
                    lazy-src="/logo.png"
                    height="50"
                    max-width="190"
                    contain
                ></v-img>
              </v-layout>
            </div>
            <router-view></router-view>
          </v-sheet>
        </v-layout>
      </v-container>
    </v-main>
  </v-fade-transition>
</template>

<script>
export default {
  data() {
    return {
      loadPage: false,
    }
  },
  created() {
  },
  mounted() {
    this.loadPage = true;
  },
  computed: {
    preloaderApp() {
      return this.$store.state.app.preloaderApp;
    },
  },
  watch: {},
  methods: {},
}
</script>
