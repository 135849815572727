import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

import SpreadsheetSvg from '@/icons/spreadsheet-svg'
import GithubSvg from '@/icons/github-svg'
import DropdownSvg from '@/icons/dropdown-svg'
import ThinTextSvg from '@/icons/thin-text-svg'

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'md',
    values: {
      spreadsheet: {
        component: SpreadsheetSvg,
      },
      github: {
        component: GithubSvg,
      },
      thinTextSvg: {
        component: ThinTextSvg,
      },
      dropdownSvg: {
        component: DropdownSvg,
      },
    },
  },
});
