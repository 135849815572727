export default {
  state: {
    tagsAll: [],
    tagsOfferFilter: [],
    tagsTypes: [],
  },
  actions: {
    async getTags (state, params) {
      return await this._vm.$api.tags.getTags(params)
    },

    async getTagsAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit'
        };

        const res = await this._vm.$api.tags.getTags(params);
        commit('SET_TAGS_ALL', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },

    async getTagsOfferFilter ({ commit }) {
      try {
        const params = {
          count: 'nolimit',
          type: ['offer_filter']
        };

        const res = await this._vm.$api.tags.getTags(params);
        commit('SET_TAGS_OFFER_FILTER', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },

    async getTagsTypes ({ commit }) {
      try {
        const params = {
          count: 'nolimit'
        };

        const res = await this._vm.$api.tags.getTagsTypes(params);
        commit('SET_TAGS_TYPES', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_TAGS_ALL (state, data) {
      state.tagsAll = data;
    },
    SET_TAGS_OFFER_FILTER (state, data) {
      state.tagsOfferFilter = data;
    },
    SET_TAGS_TYPES (state, data) {
      state.tagsTypes = data;
    },
  },
  getters: {
  },
};