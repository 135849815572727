<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <svg xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 256 256"
       class="thin-text-icon-svg"
  >
    <path xml:space="preserve"
          d="M211.99414,55.99512v32a4,4,0,1,1-8,0v-28h-72v136h28a4,4,0,0,1,0,8h-64a4,4,0,0,1,0-8h28v-136h-72v28a4,4,0,0,1-8,0v-32a4.0002,4.0002,0,0,1,4-4h160A4.0002,4.0002,0,0,1,211.99414,55.99512Z"/>
  </svg>

</template>

<script>
export default {
  name: 'GithubSvg'
}
</script>
