<style lang="scss">
    @import 'styles.scss';
</style>

<template>
    <v-snackbars :objects.sync="messages" class="snack-snack">
        <template v-slot:default="{ message }">
            <div class="text-subtitle-2" v-html="message" v-linkified/>
        </template>
        <template v-slot:action="{ close }">
            <v-btn icon small @click.native="close()">
                <v-icon small>close</v-icon>
            </v-btn>
        </template>
    </v-snackbars>
</template>

<script>
  import VSnackbars from "v-snackbars"

  export default {
    components: {
      "v-snackbars": VSnackbars
    },
    data() {
      return {
        show: false,
        message: '',
        type: '',
        delay: 7000,
        messages: [],
      }
    },
    created() {},
    computed: {
      msg() {
        return this.$store.state.app.snack
      }
    },
    watch: {
      msg(val) {
        if (val.trim() !== '') {
            this.setSnack(val);
        }
      },
    },
    methods: {
      async setSnack(val) {
        const message = val || 'Error';
        if (this.messages) {
          const index = this.messages.findIndex(messageFind => messageFind.message === message);
          if (index > -1) {
            if (this.messages[index]) this.messages.splice(index, 1, {
              message: message,
              top: true,
              color: this.$store.state.app.snackType || 'error',
              transition: "fade-transition",
              timeout: this.$store.state.app.snackDelay || 7000,
            });
            this.$store.commit('SET_SNACK', {text: '', type: 'error'});
          } else {
            this.messages.push({
              message: message,
              top: true,
              color: this.$store.state.app.snackType || 'error',
              transition: "fade-transition",
              timeout: this.$store.state.app.snackDelay || 7000,
            });
          }
        }
        this.$store.commit('SET_SNACK', {text: '', type: 'error'});

        if (this.messages && this.messages.length && this.messages.length > 7) {
          this.messages.splice(0, 1);
        }
      },
    },
  }
</script>