<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <svg viewBox="0 0 23 31"
       xmlns="http://www.w3.org/2000/svg"
  >
    <path xml:space="preserve"
          class="spreadsheet-icon-svg"
          d="M0.814584 0.182391C0.0371358 0.699048 0 1.3981 0 15.4704C0 29.8152 0.0424064 30.5385 0.904906 30.8678C1.10328 30.9436 5.90645 31.003 11.5786 30.9999C21.8323 30.9944 21.8946 30.9913 22.4456 30.4761L23 29.9577V18.6745V7.39103H20.113C17.1187 7.39103 16.0365 7.124 15.7217 6.30741C15.64 6.09522 15.5729 4.58911 15.5729 2.9607V0L8.32552 0.00357627C4.33933 0.00572206 0.959532 0.0860697 0.814584 0.182391ZM18.4479 19.9081V25.7494H11.5H4.55208V19.9081V14.0668H11.5H18.4479V19.9081ZM5.98958 16.5702V17.6431H8.38542H10.7812V16.5702V15.4973H8.38542H5.98958V16.5702ZM12.2187 16.5342V17.5709L14.5547 17.5475L16.8906 17.5239L16.9642 16.5106L17.038 15.4973H14.6282H12.2187V16.5342ZM5.98958 19.9081V20.981H8.38542H10.7812V19.9081V18.8352H8.38542H5.98958V19.9081ZM12.2187 19.9081V20.9982L14.5547 20.93L16.8906 20.8618V19.9081V18.9544L14.5547 18.8862L12.2187 18.818V19.9081ZM5.98958 23.246V24.3189H8.38542H10.7812V23.246V22.1731H8.38542H5.98958V23.246ZM12.2187 23.246V24.3189H14.6146H17.0104V23.246V22.1731H14.6146H12.2187V23.246Z"
    ></path>
  </svg>
</template>

<script>
export default {
  name: 'SpreadsheetSvg'
}
</script>
