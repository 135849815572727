export default {
  state: {
    sitesAll: [],
    mySitesAll: [
      {id: 0, name: 'All Sites'}
    ],
    sitesAllConnected: [],
    typesSites: [
      {name: 'Cover site', value: 'root'},
      {name: 'Connected', value: 'connected'},
      {name: 'Pending', value: 'pending'},
    ],
    namesTypesSites: {
      root: 'Cover site',
      connected: 'Connected',
      pending: 'Pending',
    }
  },
  actions: {
    async getSites (state, params) {
      return await this._vm.$api.sites.getSites(params)
    },
    async getSite (state, params) {
      return await this._vm.$api.sites.getSite(params)
    },
    async postSitesRequestSite (state, params) {
      return await this._vm.$api.sites.postSitesRequestSite(params)
    },
    async postSitesRequestSubdomain (state, params) {
      return await this._vm.$api.sites.postSitesRequestSubdomain(params)
    },

    async getSitesAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit'
        };

        const res = await this._vm.$api.sites.getSites(params);
        const data = res.data.data ? res.data.data : res.data;
        commit('SET_SITES_ALL', data);
        commit('SET_MY_SITES_ALL', data);
        commit('SET_SITES_ALL_CONNECTED', data.filter(site => site.type === 'connected'));
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async getSitesAllConnected ({ commit }) {
      try {
        const params = {
          count: 'nolimit',
          type: 'connected'
        };

        const res = await this._vm.$api.sites.getSites(params);
        commit('SET_SITES_ALL_CONNECTED', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_SITES_ALL (state, data) {
      state.sitesAll = data;
    },
    SET_MY_SITES_ALL (state, data) {
      state.mySitesAll = [{id: 0, name: 'All Sites'}].concat(data);
    },
    SET_SITES_ALL_CONNECTED (state, data) {
      state.sitesAllConnected = data;
    },
  },
  getters: {
  },
};
