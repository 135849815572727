var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-decoration-component"},[_c('v-toolbar',{attrs:{"dense":"","elevation":"0"}},[_c('v-btn-toggle',{attrs:{"value":_vm.weight,"color":"primary","dense":"","group":""},on:{"change":_vm.changeTextWeight}},[_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'800',"disabled":_vm.disabledProp}},on),[_c('v-icon',{staticStyle:{"font-weight":"900"}},[_vm._v("format_bold")])],1)]}}])},[_c('span',[_vm._v(" Extra bold ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'700',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_bold")])],1)]}}])},[_c('span',[_vm._v(" Bold ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'300',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("$thinTextSvg")])],1)]}}])},[_c('span',[_vm._v(" Thin ")])])],1),_c('v-btn-toggle',{attrs:{"value":_vm.textStylesComputed,"color":"primary","dense":"","group":"","multiple":""},on:{"change":_vm.changeTextStyles}},[_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'italic',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_italic")])],1)]}}])},[_c('span',[_vm._v(" Italic ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'line-through',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_strikethrough")])],1)]}}])},[_c('span',[_vm._v(" Strikethrough ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'underline',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_underline")])],1)]}}])},[_c('span',[_vm._v(" Underline ")])])],1),_c('v-spacer'),_c('v-item-group',{staticClass:"v-btn-toggle v-btn-toggle--dense v-btn-toggle--group primary--text",attrs:{"color":"primary","dense":"","group":""}},[_c('v-menu',{ref:("colors_" + _vm.valueName + "_menu"),attrs:{"top":"","eager":"","close-on-content-click":false,"disabled":_vm.disabledProp},on:{"input":_vm.colorOpenMenu},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var menu = ref.on;
var attrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.disabledProp}},'v-btn',attrs,false),Object.assign({}, tooltip, menu)),[_c('v-row',{staticClass:"flex-column",attrs:{"align":"center","justify":"center"}},[_c('v-icon',{staticClass:"cols 12"},[_vm._v("format_color_text")]),_c('v-sheet',{staticClass:"color-line",attrs:{"tile":"","height":"4","width":"26","color":_vm.color}})],1)],1)]}}],null,true)},[_c('span',[_vm._v(" Text color ")])])]}}])},[_c('v-card',[_c('v-card-text',{staticClass:"pa-0"},[_c('v-color-picker',{ref:("colors_" + _vm.valueName),attrs:{"value":_vm.color,"mode":"hexa"},on:{"input":_vm.changeTextColor}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.colorToDefault(("colors_" + _vm.valueName + "_menu"))}}},[_vm._v(" to default ")]),_c('v-spacer'),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.colorClose(("colors_" + _vm.valueName + "_menu"))}}},[_vm._v(" cancel ")]),_c('v-btn',{attrs:{"small":"","color":"success"},on:{"click":function($event){return _vm.colorSave(("colors_" + _vm.valueName), ("colors_" + _vm.valueName + "_menu"))}}},[_vm._v(" ok ")])],1)],1)],1)],1),(_vm.textAlignProp)?_c('v-spacer'):_vm._e(),(_vm.textAlignProp)?_c('v-btn-toggle',{attrs:{"value":_vm.position,"color":"primary","dense":"","group":"","mandatory":""},on:{"change":_vm.changeTextAlign}},[_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'start',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_align_left")])],1)]}}],null,false,460527636)},[_c('span',[_vm._v(" Align left ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'center',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_align_center")])],1)]}}],null,false,2542654735)},[_c('span',[_vm._v(" Align center ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'end',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_align_right")])],1)]}}],null,false,3482878880)},[_c('span',[_vm._v(" Align right ")])]),_c('v-tooltip',{attrs:{"eager":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"value":'justify',"disabled":_vm.disabledProp}},on),[_c('v-icon',[_vm._v("format_align_justify")])],1)]}}],null,false,1654403791)},[_c('span',[_vm._v(" Align justify ")])])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }