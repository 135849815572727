export default {
  state: {
  },
  actions: {
    async postCustomPage (state, params) {
      return await this._vm.$api.customPages.postCustomPage(params)
    },
    async putCustomPage (state, params) {
      return await this._vm.$api.customPages.putCustomPage(params)
    },
    async deleteCustomPage (state, params) {
      return await this._vm.$api.customPages.deleteCustomPage(params)
    },
    async pushToGitCustomPage (state, params) {
      return await this._vm.$api.customPages.pushToGitCustomPage(params)
    },
    async postCloneCustomPage (state, params) {
      return await this._vm.$api.customPages.postCloneCustomPage(params)
    },
    async postRemoveFromGitCustomPage (state, params) {
      return await this._vm.$api.customPages.postRemoveFromGitCustomPage(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};