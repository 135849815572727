export default {
  state: {
    landingPagesAll: [],
    landingPagesAllBuilder: [],
  },
  actions: {
    async getLandingPages (state, params) {
      return await this._vm.$api.landingPages.getLandingPages(params)
    },
    async getLandingPage (state, params) {
      return await this._vm.$api.landingPages.getLandingPage(params)
    },
    async postRequestLandingPage (state, params) {
      return await this._vm.$api.landingPages.postRequestLandingPage(params)
    },

    async getLandingPagesAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit',
          sort: 'created_at',
          order: 'desc',
          type: 'codes_builder'
        };

        const res = await this._vm.$api.landingPages.getLandingPages(params);
        commit('SET_LANDING_PAGES_ALL', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async getLandingPagesAllBuilder ({ commit }) {
      try {
        const params = {
          count: 'nolimit',
          sort: 'created_at',
          order: 'desc',
          type: 'page_builder'
        };

        const res = await this._vm.$api.landingPages.getLandingPages(params);
        commit('SET_LANDING_PAGES_ALL_BUILDER', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_LANDING_PAGES_ALL (state, data) {
      state.landingPagesAll = data;
    },
    SET_LANDING_PAGES_ALL_BUILDER (state, data) {
      state.landingPagesAllBuilder = data;
    },
  },
  getters: {
  },
};
