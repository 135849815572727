export default {
  state: {
    myTranslationsAll: [],
  },
  actions: {
    async getTranslations (state, params) {
      return await this._vm.$api.translations.getTranslations(params)
    },
    async postTranslation (state, params) {
      return await this._vm.$api.translations.postTranslation(params)
    },
    async putTranslation (state, params) {
      return await this._vm.$api.translations.putTranslation(params)
    },
    async deleteTranslation (state, params) {
      return await this._vm.$api.translations.deleteTranslation(params)
    },

    async getTranslationsAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit'
        };

        const res = await this._vm.$api.translations.getTranslations(params);
        commit('SET_MY_TRANSLATIONS_ALL', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
  },
  mutations: {
    SET_MY_TRANSLATIONS_ALL (state, data) {
      state.myTranslationsAll = data;
    },
  },
  getters: {
  },
};