<style lang="scss">
    @import 'styles.scss';
</style>

<template>
    <v-app-bar
            fixed
            app
            clipped-left
            extended
            extension-height="5"
            class="main-toolbar-class"
            elevation="1"
    >
        <v-app-bar-nav-icon @click.stop="setShowMenu" color="grey darken-3" v-if="windowWidth < 1264">
            <v-icon large>menu</v-icon>
        </v-app-bar-nav-icon>
        <v-app-bar-nav-icon @click.stop="setShowMenuExpand" :style="{transform: `rotate(${showMenuExpand ? '0.5turn' : '0'})`}" color="grey darken-3" v-else>
            <v-icon large>menu_open</v-icon>
        </v-app-bar-nav-icon>
      <v-img
          src="/logo.png"
          lazy-src="/logo.png"
          height="35"
          max-width="130"
          class="ml-2"
          contain
      ></v-img>
      <v-toolbar-title class="text-h5 font-weight-medium primary--text line-height-2-5">
        <span class="body-2 text--secondary mb-3 custom-version ml-1">v{{version}}</span>
      </v-toolbar-title>
        <v-spacer></v-spacer>

        <v-toolbar-title class="mr-2 grey--text text--darken-3">
            <div class="h6 text-right font-weight-regular">{{user.name}}</div>
            <div class="subtitle-2 text-right font-weight-regular mt-n1">{{user.email}}</div>
        </v-toolbar-title>
      <v-menu
          open-on-hover
          bottom
          offset-y
          eager v-if="tokenAdmin && refreshTokenAdmin"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon
                 class="ml-2"
                 v-on="on"
                 v-bind="attrs"
                 tile
          >
            <v-icon>logout</v-icon>
          </v-btn>
        </template>

        <v-list
            nav
            dense
        >
          <v-list-item-group color="primary" v-model="listGroupValue">
            <v-list-item @click="loginAsAdmin">
              <v-list-item-icon>
                <v-icon>login</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-size-14 font-weight-regular">Login as '{{adminName || 'Admin'}}'</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="logout">
              <v-list-item-icon>
                <v-icon>logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="font-size-14 font-weight-regular">Logout</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-menu>
      <v-tooltip left eager v-else>
        <template v-slot:activator="{ on }">
          <v-btn icon
                 class="ml-2"
                 @click="logout"
                 v-on="on"
                 tile
          >
            <v-icon>logout</v-icon>
          </v-btn>
        </template>
        <span>Logout</span>
      </v-tooltip>

        <v-progress-linear slot="extension"
                           height="5"
                           :active="showProgress || showProgressPage"
                           :indeterminate="showProgress || showProgressPage"
                           absolute
                           bottom
                           color="primary"
        ></v-progress-linear>
    </v-app-bar>
</template>

<script>
  export default {
    data: () => ({
      listGroupValue: null,
    }),
    created() {

    },
    watch: {},
    computed: {
      version() {
        return process.env.VUE_APP_VERSION;
      },
      windowWidth() {
        return this.$store.state.app.windowWidth;
      },
      showMenuExpand() {
        return this.$store.state.app.showMenuExpand;
      },
      showProgress() {
        return this.$store.state.app.showProgress;
      },
      showProgressPage() {
        return this.$store.state.app.showProgressPage;
      },
      user() {
        return this.$store.state.user.user;
      },
      tokenAdmin() {
        return this.$store.state.user.tokenAdmin;
      },
      adminName() {
        return this.$store.state.user.adminName;
      },
      refreshTokenAdmin() {
        return this.$store.state.user.refreshTokenAdmin;
      },
    },
    methods: {
      setShowMenu() {
        this.$store.commit('SET_SHOW_MENU');
      },
      setShowMenuExpand() {
        this.$store.commit('SET_SHOW_MENU_EXPAND');
      },
      logout() {
        setTimeout(() => this.listGroupValue = null)
        this.$store.dispatch('logout');
      },
      loginAsAdmin() {
        setTimeout(() => this.listGroupValue = null)
        this.$store.dispatch('setLoginAdminBackFromUser');
      }
    },
  }
</script>
