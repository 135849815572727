export default axios => ({
  getTranslations(params) {
    return axios.get('/translations', {params})
  },
  postTranslation(params) {
    return axios.post('/translations', params)
  },
  putTranslation(params) {
    return axios.put(`/translations/${params.id}`, params)
  },
  deleteTranslation(params) {
    return axios.delete(`/translations/${params.id}`)
  },
});