export default axios => ({
  getDeploys(params) {
    return axios.get('/deploys', {params})
  },
  getDeploy(params) {
    return axios.get(`/deploys/${params.id}`)
  },
  postDeploy(params) {
    return axios.post('/deploys', params)
  },
  putDeploy(params) {
    return axios.put(`/deploys/${params.id}`, params)
  },
  deleteDeploy(params) {
    return axios.delete(`/deploys/${params.id}`)
  },
  pushToGit(params) {
    return axios.post(`/deploys/${params.id}/push-to-git`, params)
  },
  postCloneDeploy(params) {
    return axios.post('/deploys/clone', params)
  },
  postRemoveFromGitDeploy(params) {
    return axios.post(`/deploys/${params.id}/remove-from-git`)
  },
});