export default {
  state: {
    usersAll: [],
    adminSitesAll: [],
    adminSitesAllForFilter: [
      {id: 0, name: 'All Sites'}
    ],
    adminSitesAllConnected: [],
    reposAll: [],
  },
  actions: {
    // LANDING PAGES
    async adminPostLandingPage (state, params) {
      return await this._vm.$api.admin.postLandingPage(params)
    },
    async adminPutLandingPage (state, params) {
      return await this._vm.$api.admin.putLandingPage(params)
    },
    async adminDeleteLandingPage (state, params) {
      return await this._vm.$api.admin.deleteLandingPage(params)
    },
    async adminReadyTemplate (state, params) {
      return await this._vm.$api.admin.readyTemplate(params)
    },
    async adminCloneTemplate (state, params) {
      return await this._vm.$api.admin.cloneTemplate(params)
    },
    // -------------------------

    // SITES
    async adminGetSites (state, params) {
      return await this._vm.$api.admin.getSites(params)
    },
    async adminPostSite (state, params) {
      return await this._vm.$api.admin.postSite(params)
    },
    async adminPutSite (state, params) {
      return await this._vm.$api.admin.putSite(params)
    },
    async adminDeleteSite (state, params) {
      return await this._vm.$api.admin.deleteSite(params)
    },
    async adminReadySite (state, params) {
      return await this._vm.$api.admin.readySite(params)
    },

    async adminGetSitesAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit'
        };

        const res = await this._vm.$api.admin.getSites(params);
        commit('ADMIN_SET_SITES_ALL', res.data.data ? res.data.data : res.data);
        commit('ADMIN_SET_SITES_ALL_FOR_FILTER', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async adminGetSitesAllConnected ({ commit }) {
      try {
        const params = {
          count: 'nolimit',
          type: 'connected'
        };

        const res = await this._vm.$api.admin.getSites(params);
        commit('ADMIN_SET_SITES_ALL_CONNECTED', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    // -------------------------

    // USERS
    async adminGetUsers (state, params) {
      return await this._vm.$api.admin.getUsers(params)
    },
    async adminGetUser (state, params) {
      return await this._vm.$api.admin.getUser(params)
    },
    async adminGetUsersAll ({ commit }, params) {
      try {
        const param = Object.assign({count: 'nolimit'}, params);

        const res = await this._vm.$api.admin.getUsers(param);
        commit('ADMIN_SET_USERS_ALL', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    async adminPostUser (state, params) {
      return await this._vm.$api.admin.postUser(params)
    },
    async adminPutUser (state, params) {
      return await this._vm.$api.admin.putUser(params)
    },
    async adminDeleteUser (state, params) {
      return await this._vm.$api.admin.deleteUser(params)
    },
    // -------------------------

    // TAGS
    async adminPostTag (state, params) {
      return await this._vm.$api.admin.postTag(params)
    },
    async adminPutTag (state, params) {
      return await this._vm.$api.admin.putTag(params)
    },
    async adminDeleteTag (state, params) {
      return await this._vm.$api.admin.deleteTag(params)
    },

    async adminAttachTags (state, params) {
      return await this._vm.$api.admin.attachTags(params)
    },
    // -------------------------

    // DEPLOYS
    async adminGetDeploys (state, params) {
      return await this._vm.$api.admin.getDeploys(params)
    },
    async adminGetDeploy (state, params) {
      return await this._vm.$api.admin.getDeploy(params)
    },
    async adminPostDeploy (state, params) {
      return await this._vm.$api.admin.postDeploy(params)
    },
    async adminPutDeploy (state, params) {
      return await this._vm.$api.admin.putDeploy(params)
    },
    async adminDeleteDeploy (state, params) {
      return await this._vm.$api.admin.deleteDeploy(params)
    },
    async adminPushToGit (state, params) {
      return await this._vm.$api.admin.pushToGit(params)
    },
    async adminPostCloneDeploy (state, params) {
      return await this._vm.$api.admin.postCloneDeploy(params)
    },
    async adminPostRemoveFromGitDeploy (state, params) {
      return await this._vm.$api.admin.postRemoveFromGitDeploy(params)
    },
    // -------------------------

    // CUSTOM PAGES
    async adminPostCustomPage (state, params) {
      return await this._vm.$api.admin.postCustomPage(params)
    },
    async adminPutCustomPage (state, params) {
      return await this._vm.$api.admin.putCustomPage(params)
    },
    async adminDeleteCustomPage (state, params) {
      return await this._vm.$api.admin.deleteCustomPage(params)
    },
    async adminPushToGitCustomPage (state, params) {
      return await this._vm.$api.admin.pushToGitCustomPage(params)
    },
    async adminPostCloneCustomPage (state, params) {
      return await this._vm.$api.admin.postCloneCustomPage(params)
    },
    async adminPostRemoveFromGitCustomPage (state, params) {
      return await this._vm.$api.admin.postRemoveFromGitCustomPage(params)
    },
    // -------------------------

    // GITHAB
    async adminGetRepos (state, params) {
      return await this._vm.$api.admin.getRepos(params)
    },
    async adminPostRepo (state, params) {
      return await this._vm.$api.admin.postRepo(params)
    },
    async adminPostSetSecrets (state, params) {
      return await this._vm.$api.admin.postSetSecrets(params)
    },
    async adminGetSecrets (state, params) {
      return await this._vm.$api.admin.getSecrets(params)
    },

    async adminGetReposAll ({ commit }) {
      try {
        const params = {
          count: 'nolimit',
          type: 'connected'
        };

        const res = await this._vm.$api.admin.getRepos(params);
        commit('ADMIN_SET_REPOS_ALL', res.data.data ? res.data.data : res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || '', type: 'error'});
      }
    },
    // -------------------------

    // LOGS
    async adminGetLogsConversions (state, params) {
      return await this._vm.$api.admin.getLogsConversions(params)
    },
    async adminExportCSVConversions (state, params) {
      return await this._vm.$api.admin.exportCSVConversions(params)
    },
    // -------------------------
  },
  mutations: {
    ADMIN_SET_USERS_ALL (state, data) {
      state.usersAll = data;
    },
    ADMIN_SET_SITES_ALL (state, data) {
      state.adminSitesAll = data;
    },
    ADMIN_SET_SITES_ALL_FOR_FILTER (state, data) {
      state.adminSitesAllForFilter = [{id: 0, name: 'All Sites'}].concat(data);
    },
    ADMIN_SET_SITES_ALL_CONNECTED (state, data) {
      state.adminSitesAllConnected = data;
    },
    ADMIN_SET_REPOS_ALL (state, data) {
      state.reposAll = data;
    },
  },
  getters: {
  },
};
