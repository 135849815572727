export default axios => ({
  getSites(params) {
    return axios.get('/sites', {params})
  },
  getSite(params) {
    return axios.get(`/sites/${params.id}`)
  },
  postSitesRequestSite(params) {
    return axios.post('/sites/request-site', params)
  },
  postSitesRequestSubdomain(params) {
    return axios.post('/sites/request-subdomain', params)
  },
});