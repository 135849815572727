import Main from '@/pages/Main.vue'
import AuthMain from '@/pages/auth-pages/AuthMain.vue'

export const authPages = [
  {
    component: AuthMain,
    path: '/login_index',
    name: 'login_index',
    meta: {
      title: 'Login'
    },
    children: [
      { path: '/login', name: 'login', component: () => import('@/pages/auth-pages/login') }
    ]
  },
  {
    component: AuthMain,
    path: '/signup_index',
    name: 'signup_index',
    meta: {
      title: 'Sign Up'
    },
    children: [
      { path: '/signup', name: 'signup', component: () => import('@/pages/auth-pages/signup') }
    ]
  }
];

export const errorPages = [
  {
    path: '/403',
    meta: {
      title: '403-Insufficient permissions'
    },
    name: 'error-403',
    component: () => import('@/pages/error-pages/403')
  },
  {
    path: '/500',
    meta: {
      title: '500-Server error'
    },
    name: 'error-500',
    component: () => import('@/pages/error-pages/500')
  },
  {
    path: '/404',
    name: 'error-404',
    alias: '*',
    meta: {
      title: '404-Page does not exist'
    },
    component: () => import('@/pages/error-pages/404')
  }
];

export const appRouter = [
  {
    path: '/landing-pages',
    name: 'landing-pages',
    title: 'Landing pages',
    access: 3,
    icon: 'web',
    hideChildren: false,
    arrayRouts: true,
    component: Main,
    group: 'landing-pages',
    children: [
      { icon: 'language', path: '/landing-pages/sites', title: 'Sites', name: 'my-sites', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-sites') },
      { icon: 'account_tree', path: '/landing-pages/pages', title: 'Pages', name: 'my-pages', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-pages') },
      { icon: 'insert_page_break', path: '/landing-pages/page-builder', title: 'Pages Builder', name: 'my-page-builder', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-page-builder') },
      { icon: 'library_books', path: '/landing-pages/library', title: 'Library', name: 'library', access: 3, component: () => import('@/pages/main-pages/landing-pages-library') },
      { icon: 'html', path: '/landing-pages/scripts', title: 'Scripts', name: 'my-scripts', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-scripts') },
      { icon: 'link', path: '/landing-pages/urls', title: 'URLs', name: 'my-urls', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-urls') },
      { icon: 'data_object', path: '/landing-pages/variables', title: 'Variables', name: 'my-variables', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-variables') },
      { icon: 'question_mark', path: '/landing-pages/quiz-questions', title: 'Quiz Questions', name: 'my-quiz-questions', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-quiz-questions') },
      { icon: 'translate', path: '/landing-pages/translations', title: 'Translations', name: 'my-translations', access: 3, component: () => import('@/pages/main-pages/landing-pages-my-translations') },
    ]
  },
  {
    path: '/redtrack-api_index',
    name: 'redtrack-api_index',
    title: 'Redtrack',
    access: 2,
    icon: 'list_alt',
    component: Main,
    children: [
      { path: '/redtrack-api', title: 'Redtrack', access: 2, name: 'redtrack-api', component: () => import('@/pages/main-pages/redtrack-api') }
    ]
  },
  {
    path: '/tune-revenue_index',
    name: 'tune-revenue_index',
    title: 'Tune Revenue',
    access: 2,
    icon: 'monetization_on',
    component: Main,
    children: [
      { path: '/tune-revenue', title: 'Tune Revenue', access: 2, name: 'tune-revenue', component: () => import('@/pages/main-pages/tune-revenue-page') }
    ]
  },
  {
    path: '/administration',
    name: 'administration',
    title: 'Administration',
    access: 1,
    icon: 'admin_panel_settings',
    hideChildren: false,
    arrayRouts: true,
    component: Main,
    group: 'administration',
    children: [
      { icon: 'people', path: '/administration/users', title: 'Users', name: 'admin-users', access: 1, component: () => import('@/pages/admin-pages/pages/users-page') },
      { icon: 'language', path: '/administration/sites', title: 'Sites', name: 'admin-sites', access: 1, component: () => import('@/pages/admin-pages/pages/sites-page') },
      { icon: 'account_tree', path: '/administration/pages', title: 'Pages', name: 'admin-pages', access: 1, component: () => import('@/pages/admin-pages/pages/pages-page') },
      { icon: 'insert_page_break', path: '/administration/page-builder', title: 'Page Builder', name: 'admin-page-builder', access: 1, component: () => import('@/pages/admin-pages/pages/pages-builder-page') },
      { icon: 'library_books', path: '/administration/library', title: 'Library', name: 'admin-library', access: 1, component: () => import('@/pages/admin-pages/pages/landing-pages-page') },
      { icon: 'label', path: '/administration/tags', title: 'Tags', name: 'admin-tags', access: 1, component: () => import('@/pages/admin-pages/pages/tags-page') },
      { icon: '$github', path: '/administration/github', title: 'Github', name: 'admin-github', access: 1, component: () => import('@/pages/admin-pages/pages/github-page') },
      { icon: 'text_snippet', path: '/administration/tune-conversions', title: 'Tune Conversions', name: 'tune-conversions', access: 1, component: () => import('@/pages/admin-pages/pages/tune-log-conversions-page') },
    ]
  },
];
export const otherRouter = [
  {
    path: '/',
    name: 'main',
    title: 'Main',
    icon: 'dashboard',
    redirect: appRouter[0].children[0].path,
    component: Main
  }
];

export const routers = [
  ...authPages,
  ...appRouter,
  ...otherRouter,
  ...errorPages
];
