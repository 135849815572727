<style lang="scss">
    @import 'styles.scss';
</style>

<template>
  <v-navigation-drawer
      :expand-on-hover="windowWidth >= 1264 && showMenuExpand"
      :mini-variant="windowWidth >= 1264 && showMenuExpand"
      :permanent="windowWidth >= 1264 && showMenuExpand"
      app
      clipped
      v-model="showMenu"
      class="elevation-1 navigation-drawer-main"
      touchless
      id="navigation-drawer-main"
      width="220"
      color="grey lighten-5"
  >
    <v-list
        nav
        dense
        class="px-0"
        v-if="load"
        expand
    >
      <template v-for="item in menuList">
        <v-list-item v-if="!item.arrayRouts"
                     :key="item.name"
                     :to="item.children ? item.children[0].path : item.path"
                     link
                     color="primary"
                     class="mx-2"
        >
          <v-list-item-icon class="mr-5">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-group v-else
                      :key="item.name"
                      :group="item.group"
                      no-action
                      color="primary"
                      class="expanded-group"
                      ref="expandedGroup"
        >
          <template v-slot:activator>
            <v-list-item-icon class="mr-5">
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <template v-for="subItem in item.children">
            <v-list-item link
                         :key="subItem.title"
                         :to="subItem.children ? subItem.children[0].path : subItem.path"
                         v-if="!subItem.noMenu"
                         class="pl-5 mx-2"
            >
              <v-list-item-icon class="mr-5">
                <v-icon>{{ subItem.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
        </v-list-group>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
  export default {
    data: () => ({
      load: false,
    }),
    computed: {
      windowWidth() {
        return this.$store.state.app.windowWidth;
      },
      showMenuExpand() {
        return this.$store.state.app.showMenuExpand;
      },
      showMenu: {
        get() {
          return this.$store.state.app.showMenu;
        },
        set(newValue) {
          if (newValue !== this.showMenu) {
            this.$store.commit('SET_SHOW_MENU');
          }
        }
      },
      menuList() {
        return this.$store.state.app.menuList;
      },
    },
    watch: {
      '$route'() {
        this.$store.dispatch('updateMenulist');
      },
    },
    created() {
    },
    mounted() {
      this.$nextTick(() => {
        setTimeout(() => {
          this.load = true;
          this.$nextTick(() => {
            let el = document.getElementById('navigation-drawer-main');
            if (el) {
              let elScroll = el.getElementsByClassName('v-navigation-drawer__content');
              if (elScroll && elScroll[0]) {
                let scrollTo = elScroll[0].getElementsByClassName('v-list-item--active');
                if (scrollTo && scrollTo[0]) {
                  this.$nextTick(() => {
                    scrollTo[0].scrollIntoView({block: 'start', inline: 'nearest', behavior: 'smooth'})
                  })
                }
              }
            }
          })
        }, 300)
      })
    },
    methods: {
    },
  }
</script>