<template>
  <v-fade-transition>
    <div id="main-div"
         v-show="!preloaderApp"
         class="fill-height"
    >
      <ScrollTop/>
      <NavMenu/>
      <Toolbar/>
      <Footer/>
      <v-main class="main-content-window fill-height">
        <v-container fluid class="pa-0 fill-height-max">
          <router-view></router-view>
        </v-container>
      </v-main>
    </div>
  </v-fade-transition>
</template>

<script>
import NavMenu from '@/main-components/nav-menu'
import Toolbar from '@/main-components/toolbar'
import Footer from '@/main-components/footer'
import ScrollTop from '@/main-components/scroll-top'

export default {
  components: {
    NavMenu,
    Toolbar,
    Footer,
    ScrollTop
  },
  data() {
    return {}
  },
  created() {
  },
  mounted() {
    this.$store.dispatch('updateMenulist');
    // setTimeout(() => this.$store.commit('SET_PRELOADER_APP', false), 5000);
  },
  computed: {
    preloaderApp() {
      return this.$store.state.app.preloaderApp;
    }
  },
  watch: {

  },
  methods: {
  },
}
</script>
