<style lang="scss">
    @import 'styles.scss';
</style>

<template>
    <v-dialog v-model="dialog" :max-width="options.width" @keydown.esc.prevent.exact="cancel" @keydown.enter.prevent.exact="agree"
              :style="{ zIndex: options.zIndex }" :persistent="options.persistent">
        <v-card class="confirm-modal">
            <v-toolbar dark :color="options.color" dense flat>
                <v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
            </v-toolbar>
            <v-card-text>
                <span v-show="!!message" v-html="message"/>
                <div v-if="options.blocked" class="mt-5">
                    <div v-html="termsBlocked"></div>
                    <v-checkbox color="primary" v-model="checkbox" label="I Agree"></v-checkbox>
                </div>
            </v-card-text>
            <v-card-actions class="pt-0">
                <v-spacer></v-spacer>
                <v-btn small class="mr-1" @click.native="close" v-if="options.cancel">Cancel</v-btn>
                <v-btn small class="mr-1" @click.native="cancel" v-if="options.noCancel">No</v-btn>
                <v-btn small color="success"  @click.native="agree" :disabled="options.blocked && !checkbox">{{options.noCancel ? 'Yes' : 'Ok'}}</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      checkbox: false,
      options: {
        color: 'primary',
        width: 350,
        zIndex: 250,
        persistent: false,
        cancel: false,
        blocked: false,
        noCancel: true
      },
      defaultOptions: {
        color: 'primary',
        width: 350,
        zIndex: 250,
        persistent: false,
        cancel: false,
        blocked: false,
        noCancel: true
      }
    }),
    computed: {
      termsBlocked () {
        return this.$store.state.app.termsBlocked;
      },
    },
    methods: {
      open(title, message, options) {
        this.dialog = true;
        this.title = title;
        this.message = message;
        this.options = Object.assign(this.options, options);
        return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject
        })
      },
      agree() {
        if (!this.options.blocked || this.options.blocked && this.checkbox) {
          this.resolve(true);
          this.close();
        }
      },
      cancel() {
        if (this.options.noCancel) {
          this.resolve(false);
          this.close();
        }
      },
      close() {
        this.dialog = false;
        this.reject();
        setTimeout(() => {
          this.options = Object.assign(this.options, this.defaultOptions);
          this.checkbox = false;
        }, 300)
      }
    }
  }
</script>