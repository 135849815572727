export default axios => ({
  getCustomCodes(params) {
    return axios.get('/custom-codes', {params})
  },
  postCustomCode(params) {
    return axios.post('/custom-codes', params)
  },
  putCustomCode(params) {
    return axios.put(`/custom-codes/${params.id}`, params)
  },
  deleteCustomCode(params) {
    return axios.delete(`/custom-codes/${params.id}`)
  },
  postCloneCustomCode(params) {
    return axios.post('/custom-codes/clone', params)
  },
  postQuizCustomCode(params) {
    return axios.post('/custom-codes/quiz-questions', params)
  },
  putQuizCustomCode(params) {
    return axios.put(`/custom-codes/quiz-questions/${params.id}`, params)
  },
});