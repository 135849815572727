export default axios => ({
  getLandingPages(params) {
    return axios.get('/landing-pages', {params})
  },
  getLandingPage(params) {
    return axios.get(`/landing-pages/${params.id}`)
  },
  postRequestLandingPage(params) {
    return axios.post('landing-pages/request-new', params)
  }
});