import Cookies from "js-cookie";
import {appRouter} from '@/router/routers';

const sizeUtil = function (size) {
  return size >= 1264;
};

const show_menu_expand = Cookies.get('showMenuExpand') ? Cookies.get('showMenuExpand') === 'true' : false;

export default {
  state: {
    showMenuExpand: show_menu_expand,
    preloaderApp: true,
    windowWidth: 0,
    windowActive: true,
    showMenu: false,
    showProgress: false,
    showProgressPage: false,
    rowsPerPageItems: [5,10,25,50,100],
    leaderTab: false,
    menuList: [],

    snack: '',
    snackType: '',
    snackDelay: 7000,
  },
  actions: {
    updateMenulist ({commit, rootState}) {
      // role === 1 - Admin
      // role === 2 - User
      // role === 3 - Affiliate
      const roleName = rootState.user.user.role;
      let role;

      if (roleName === 'admin') {
        role = 1;
      } else if (roleName === 'user') {
        role = 2;
      } else {
        role = 3;
      }

      let menuList = [];
      appRouter.forEach((item) => {
        if (item.title === 'Landing pages' && item.access) {
          if (role <= item.access) {
            let children = [];
            item.children.forEach(child => {
              if (role <= child.access) children.push(child)
            })
            menuList.push(Object.assign({}, item, {children: children}))
          }
        } else if (item.access) {
          if (role <= item.access) menuList.push(item)
        } else {
          menuList.push(item)
        }
      });
      commit('SET_MENU_LIST', menuList);
    },
  },
  mutations: {
    SET_MENU_LIST (state, menuList) {
      state.menuList = menuList;
    },
    SET_LEADER_TAB (state, value) {
      state.leaderTab = value;
    },
    SET_PRELOADER_APP (state, value) {
      state.preloaderApp = value;
      window.dispatchEvent(new Event("resize"));
    },
    SET_SHOW_MENU (state) {
      state.showMenu = !state.showMenu;
    },
    SET_SHOW_MENU_EXPAND (state) {
      const newValue = !state.showMenuExpand;
      state.showMenuExpand = newValue;
      Cookies.set('showMenuExpand', newValue, {expires: 365});
    },
    SET_SHOW_PROGRESS_HIDE (state) {
      state.showProgress = false;
    },
    SET_SHOW_PROGRESS_SHOW (state) {
      state.showProgress = true;
    },
    SET_SHOW_PROGRESS_PAGE_HIDE (state) {
      state.showProgressPage = false;
    },
    SET_SHOW_PROGRESS_PAGE_SHOW (state) {
      state.showProgressPage = true;
    },
    SET_WINDOW_WIDTH (state, windowWidth) {
      if (state.windowWidth !== windowWidth) {
        state.windowWidth = windowWidth;
        state.showMenu = sizeUtil(state.windowWidth);
        state.showMenuChat = sizeUtil(state.windowWidth);
      }
    },
    SET_WINDOW_ACTIVE (state, windowActive) {
      state.windowActive = windowActive;
    },

    SET_SNACK(state, {text, type, delay = 7000}) {
      if (typeof text === 'string') {
        state.snack = text;
        // state.snack = text === '' ? 'Error' : text;
      } else {
        let string = '';
        for (let [key, value] of Object.entries(text)) {
          if (typeof value === 'string') {
            string = `${string} ${key === 'message' ? value + ' ' : ''}`
          } else if (value.length && Array.isArray(value) && typeof value !== 'string') {
            if (value.length > 10) {
              string = string + ' '
            } else {
              value.forEach((val) => {
                if (typeof val === 'string') {
                  string = string + val + ' '
                } else {
                  for (let [k, v] of Object.entries(val)) {
                    if (typeof v === 'string' && k === 'message') {
                      string = string + v + ' '
                    }
                  }
                }
              })
            }
          } else {
            for (let [name, str] of Object.entries(value)) {
              if (str.length && Array.isArray(str) && typeof str !== 'string') {
                str.forEach((s) => {
                  if (typeof s === 'string') {
                    string = string + s + ' '
                  } else {
                    for (let [k, v] of Object.entries(s)) {
                      if (typeof v === 'string' && k === 'message') {
                        string = string + v + ' '
                      }

                    }
                  }
                })
              } else {
                if (name === 'message') string = string + str + ' '
              }
            }
          }
        }

        state.snack = string.trim() === '' ? 'Error' : string;
      }
      state.snackType = type;
      state.snackDelay = delay;
    },
  },
  getters: {}
};