<style lang="scss">
    @import 'styles.scss';
</style>

<template>
  <div class="markdown-textarea">
    <v-toolbar dense elevation="0">
      <v-item-group
          class="v-btn-toggle v-btn-toggle--dense v-btn-toggle--group primary--text"
          color="primary"
          dense
          group
      >
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="wrapWith('@>', 'highlight text', '<@' )" :disabled="disabledProp">
              <v-icon style="font-weight:900">format_bold</v-icon>
            </v-btn>
          </template>
          <span>
            Extra bold
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="wrapWith('**', 'highlight text')" :disabled="disabledProp">
              <v-icon>format_bold</v-icon>
            </v-btn>
          </template>
          <span>
            Bold
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="wrapWith('&>', 'highlight text', '<&' )" :disabled="disabledProp">
              <v-icon>$thinTextSvg</v-icon>
            </v-btn>
          </template>
          <span>
            Thin
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="wrapWith('*', 'highlight text')" :disabled="disabledProp">
              <v-icon>format_italic</v-icon>
            </v-btn>
          </template>
          <span>
            Italic
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="wrapWith('~~', 'highlight text')" :disabled="disabledProp">
              <v-icon>format_strikethrough</v-icon>
            </v-btn>
          </template>
          <span>
            Strikethrough
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" @click="wrapWith('^>', 'highlight text', '<^')" :disabled="disabledProp">
              <v-icon>format_underline</v-icon>
            </v-btn>
          </template>
          <span>
            Underline
          </span>
        </v-tooltip>
      </v-item-group>

      <v-spacer></v-spacer>

      <v-item-group
          class="v-btn-toggle v-btn-toggle--dense v-btn-toggle--group primary--text"
          color="primary"
          dense
          group
      >
        <v-menu top eager
                :close-on-content-click="false"
                :ref="`colors_${valueName}_menu`"
                @input="colorOpenMenu"
                :disabled="disabledProp"
        >
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip eager top>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn  v-bind="attrs"
                        v-on="{ ...tooltip, ...menu }"
                        :disabled="disabledProp"
                >
                  <v-icon>format_color_text</v-icon>
                </v-btn>
              </template>
              <span>
                Text color
              </span>
            </v-tooltip>

          </template>
          <v-card>
            <v-card-text class="pa-0">
              <v-color-picker :ref="`colors_${valueName}`"
                              v-model="colorText"
                              mode="hexa"
              />
            </v-card-text>
            <v-card-actions>
              <v-btn small
                     color="primary"
                     @click="colorToDefault(`colors_${valueName}_menu`)"
              >
                to default
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn small
                     @click="colorClose(`colors_${valueName}_menu`)"
              >
                cancel
              </v-btn>
              <v-btn small
                     color="success"
                     @click="colorSave(`colors_${valueName}`, `colors_${valueName}_menu`)"
              >
                ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-menu>
      </v-item-group>

      <v-spacer v-if="textAlignProp"></v-spacer>

      <v-btn-toggle v-if="textAlignProp"
          :value="position"
          color="primary"
          dense
          group
          mandatory
          @change="changeTextAlign"
      >
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'start'" :disabled="disabledProp">
              <v-icon>format_align_left</v-icon>
            </v-btn>
          </template>
          <span>
            Align left
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'center'" :disabled="disabledProp">
              <v-icon>format_align_center</v-icon>
            </v-btn>
          </template>
          <span>
            Align center
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'end'" :disabled="disabledProp">
              <v-icon>format_align_right</v-icon>
            </v-btn>
          </template>
          <span>
            Align right
          </span>
        </v-tooltip>
        <v-tooltip eager top>
          <template v-slot:activator="{ on }">
            <v-btn v-on="on" :value="'justify'" :disabled="disabledProp">
              <v-icon>format_align_justify</v-icon>
            </v-btn>
          </template>
          <span>
            Align justify
          </span>
        </v-tooltip>
      </v-btn-toggle>
    </v-toolbar>

    <slot ref="slot"></slot>
  </div>
</template>

<script>
import { startsWith, endsWith } from 'lodash'

export default {
  props: {
    valueName: {
      type: String,
      default() {
        return '';
      }
    },
    position: {
      type: String,
      default() {
        return 'center';
      }
    },
    colorTextProp: {
      type: String,
      default() {
        return '#000000FF';
      }
    },
    defaultTextProp: {
      type: String,
      default() {
        return '#000000FF';
      }
    },
    disabledProp: {
      type: Boolean,
      default() {
        return false;
      }
    },
    textAlignProp: {
      type: Boolean,
      default() {
        return true;
      }
    },
  },
  data: () => ({
    colorText: '#000000FF',
    changedColor: null,
    loadingColorText: false,
  }),
  watch: {
  },
  created() {
    this.updateColor = this.$_.debounce((e) => {
      if (!this.loadingColorText) this.wrapWith(`#color${e}>`,'highlight text',`<color${e}#`);
    }, 300)
  },
  mounted() {
    this.$watch('colorText', this.updateColor);
  },
  methods: {
    getContent() {
      const el = document.getElementById(this.valueName)
      return {
        text: el.value,
        start: el.selectionStart,
        end: el.selectionEnd,
      }
    },
    updateContent(text, start, end) {
      const el = document.getElementById(this.valueName);
      this.$emit('updateMarkdownContent', {text, start, end, el});
    },
    wrapWith(pattern, placeholder = '', patternEnd = '', defaultColor = false, props = null) {
      let { text, start, end } = props ? props : this.getContent()
      let { before, selection, after } = cutTextWithSelection(text, start, end)
      let wrappedContent = selection || text || placeholder || ''
      if (!selection) before = after = '';
      patternEnd = patternEnd || pattern

      let keepItalicPattern = pattern === '*'
          && endsWith(before, '**') && !endsWith(before, '***')
          && startsWith(after, '**') && !startsWith(after, '***')

      let removePattern = endsWith(before, pattern)
          && startsWith(after, patternEnd)
          && !keepItalicPattern

      let removePatternColor = null,
          beforePattern = null,
          afterPattern = null;

      const colorRegexpStart = /(#color#[a-fA-F0-9]{3,8}>)(?!.*#color#[a-fA-F0-9]{3,8}>)/gm;
      const colorRegexpEnd = /<color#[a-fA-F0-9]{3,8}#?/m;
      if (colorRegexpStart.test(pattern)) {
        beforePattern = before.match(colorRegexpStart);
        afterPattern = after.match(colorRegexpEnd);

        if (beforePattern && afterPattern) {
          removePatternColor = endsWith(before, beforePattern[0])
              && startsWith(after, afterPattern[0])
        }
      }

      if (removePatternColor) {
        if ((beforePattern[0] === pattern && afterPattern[0] === patternEnd) || defaultColor) {
          before = before.slice(0, - beforePattern[0].length);
          after = after.slice(afterPattern[0].length)
        } else {
          before = before.slice(0, - beforePattern[0].length);
          before = before + pattern;
          after = after.slice(afterPattern[0].length);
          after = patternEnd + after;
        }
      } else if (colorRegexpStart.test(pattern)) {
        const arr = [
          {start: '@>', end: '<@'},
          {start: '&>', end: '<&'},
          {start: '^>', end: '<^'},
          {start: '~~', end: '~~'},
          {start: '**', end: '**'},
          {start: '*', end: '*'},
        ]
        let returnConst = false;
        arr.forEach(patterConst => {
          if (endsWith(before, patterConst.start) && startsWith(after, patterConst.end)) {
            this.wrapWith(pattern, placeholder, patternEnd, defaultColor, {text, start: start - patterConst.start.length, end: end + patterConst.end.length})
            returnConst = true;
            return;
          }
        })
        if (returnConst) return;
        before = removePattern ? before.slice(0, - pattern.length) : before + pattern
        after = removePattern ? after.slice(patternEnd.length) : patternEnd + after
      } else {
        before = removePattern ? before.slice(0, - pattern.length) : before + pattern
        after = removePattern ? after.slice(patternEnd.length) : patternEnd + after
      }

      return this.updateContent(
          before + wrappedContent + after,
          before.length,
          before.length + wrappedContent.length,
      )
    },
    changeTextAlign(value) {
      this.$emit('changeTextAlign', value);
    },
    colorSave(ref, refMenu) {
      if (this.$refs[ref]) {
        if (this.$refs[refMenu]) this.colorClose(refMenu, true);
      }
    },
    colorToDefault(refMenu) {
      let { text, start, end } = this.getContent();
      let { before, after } = cutTextWithSelection(text, start, end);

      const colorRegexpStart = /(#color#[a-fA-F0-9]{3,8}>)(?!.*#color#[a-fA-F0-9]{3,8}>)/gm,
          colorRegexpEnd = /<color#[a-fA-F0-9]{3,8}#?/m,
          beforePattern = before.match(colorRegexpStart),
          afterPattern = after.match(colorRegexpEnd);

      if (beforePattern && beforePattern[0] && afterPattern && afterPattern[0]) this.wrapWith(`#color${this.defaultTextProp}>`,'highlight text',`<color${this.defaultTextProp}#`, true);
      if (this.$refs[refMenu]) this.colorClose(refMenu, true);
    },
    colorClose(refMenu, save = false) {
      if (save) {
        this.changedColor = null;
        this.$refs[refMenu].save();
      } else {
        this.$refs[refMenu].save();
      }
    },
    colorOpenMenu(val) {
      this.loadingColorText = true;
      let { text, start, end } = this.getContent();
      let { before, after } = cutTextWithSelection(text, start, end);

      const colorRegexpStart = /(#color#[a-fA-F0-9]{3,8}>)(?!.*#color#[a-fA-F0-9]{3,8}>)/gm,
          colorRegexpEnd = /<color#[a-fA-F0-9]{3,8}#?/m,
          beforePattern = before.match(colorRegexpStart),
          afterPattern = after.match(colorRegexpEnd);

      if (val) {
        if (beforePattern && beforePattern[0] && afterPattern && afterPattern[0]) {
          this.changedColor = this.colorText = beforePattern[0].replace('#color', '').replace('>', '');
        } else if (this.changedColor !== this.colorText) {
          this.changedColor = this.colorText = this.colorTextProp;
        }
      } else {
        if (this.changedColor !== null) {
          this.colorText = this.changedColor;
          if (beforePattern && beforePattern[0] && afterPattern && afterPattern[0]) this.wrapWith(`#color${this.colorText}>`,'highlight text',`<color${this.colorText}#`);
          this.changedColor = null;
        }
        const el = document.getElementById(this.valueName);
        this.$nextTick(() => el.focus())
      }
      setTimeout(() => this.loadingColorText = false, 500)
    },
  },
  render() {
    return this.$slots.default[0]
  },

}

function cutTextWithSelection(text, start, end) {
  return {
    before: text.substring(0, start),
    selection: text.substring(start, end),
    after: text.substring(end, text.length),
  }
}
</script>
