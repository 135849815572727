<style lang="scss">
@import 'styles.scss';
</style>

<template>
  <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" class="dropdown-icon-svg">
    <rect height="4" width="16" x="8.979" y="22"></rect>
    <rect height="4" width="16" x="8.979" y="29"></rect>
    <rect height="4" width="16" x="8.979" y="36"></rect>
    <path xml:space="preserve"
          d="M31.979,0h-30v14.167V18v30h30V18h14.043V0H31.979z M27.979,44h-22V18h22V44z M27.979,14h-22V4h22V14z M37,11.422L33.086,6.62h7.83L37,11.422z"></path>
  </svg>
</template>

<script>
export default {
  name: 'DropdownSvg'
}
</script>
