import Cookies from 'js-cookie';
import {router} from '@/router';

const token = Cookies.get('RTT');
const refresh_token = localStorage.getItem('RRTT');
const token_admin = Cookies.get('RTTA');
const refresh_token_admin = localStorage.getItem('RRTTA');
const stay_signed_in = Cookies.get('staySignedIn');
const admin_name = Cookies.get('adminName');

export default {
  state: {
    token: token,
    refreshToken: refresh_token,
    tokenAdmin: token_admin,
    refreshTokenAdmin: refresh_token_admin,
    adminName: admin_name,
    staySignedIn: stay_signed_in,
    user: {},
  },
  actions: {
    async setLogin({ commit }, data) {
      try {
        let res = await this._vm.$api.user.setLogin(data);
        res.data.staySignedIn = data.staySignedIn;
        commit('SET_LOGIN', res.data);

        this._vm.$broadcastChannel.postMessage({type: 'login', payload: res.data});
        this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'main'});

        return res;
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    async setRegister({ commit, dispatch }, data) {
      try {
        const res = await this._vm.$api.user.setRegister(data);

        await dispatch('setLogin', {email: data.get('email'), password: data.get('password'), staySignedIn: true})

        return res;
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    async getMe({ commit }) {
      try {
        const res = await this._vm.$api.user.setMe();
        commit('SET_USER', res.data);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    async refreshToken({ commit }, data) {
      try {
        const res = await this._vm.$api.user.refreshToken(data);
        commit('SET_LOGIN', res.data);

        return res
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        throw error;
      }
    },
    logout({ commit }) {
      commit('SET_PRELOADER_APP', true);
      commit('SET_LOGOUT');
      commit('SET_WINDOW_WIDTH', window.innerWidth);
      setTimeout(() => commit('SET_PRELOADER_APP', false), 1000);

      if (router?.history?.current?.name !== 'login') {
        router.push({
          name: 'login'
        });
      }

      this._vm.$broadcastChannel.postMessage({type: 'logout'});
      this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'login'});
    },
    async setLoginUserByAdmin({ commit, state }, data) {
      try {
        commit('SET_PRELOADER_APP', true);
        let res = await this._vm.$api.admin.setLoginAsUser(data);
        res.data.staySignedIn = false;

        const tokenAdmin = state.token;
        const refreshTokenAdmin = state.refreshToken;
        const adminName = state.user?.name;

        commit('SET_LOGOUT');

        if (router?.history?.current?.name !== 'login') {
          await router.push({
            name: 'login'
          });
        }
        this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'login'});

        commit('SET_ADMIN_CREDENTIALS', {token: tokenAdmin, refresh_token: refreshTokenAdmin, admin_name: adminName});
        this._vm.$broadcastChannel.postMessage({type: 'loginAsUser', payload: {token: tokenAdmin, refresh_token: refreshTokenAdmin, admin_name: adminName}});

        commit('SET_WINDOW_WIDTH', window.innerWidth);

        commit('SET_LOGIN', res.data);
        this._vm.$broadcastChannel.postMessage({type: 'login', payload: res.data});

        this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'main'});
        if (router?.history?.current?.name !== 'main') {
          await router.push({
            name: 'main'
          });
        }
        this._vm.$broadcastChannel.postMessage({type: 'routerReload'});
        setTimeout(() => commit('SET_PRELOADER_APP', false), 1000);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        commit('SET_PRELOADER_APP', false);
        throw error;
      }
    },
    async setLoginAdminBackFromUser({ commit, state }) {
      try {
        commit('SET_PRELOADER_APP', true);

        const tokenAdmin = state.tokenAdmin;
        const refreshTokenAdmin = state.refreshTokenAdmin;

        if (!tokenAdmin || !refreshTokenAdmin) throw 'Error';

        commit('SET_LOGOUT');

        if (router?.history?.current?.name !== 'login') {
          await router.push({
            name: 'login'
          });
        }
        this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'login'});

        commit('DELETE_ADMIN_CREDENTIALS');
        this._vm.$broadcastChannel.postMessage({type: 'deleteAdminCredentials'});

        commit('SET_WINDOW_WIDTH', window.innerWidth);

        commit('SET_LOGIN', {token: tokenAdmin, refresh_token: refreshTokenAdmin, staySignedIn: true});
        this._vm.$broadcastChannel.postMessage({type: 'login', payload: {token: tokenAdmin, refresh_token: refreshTokenAdmin, staySignedIn: true}});

        this._vm.$broadcastChannel.postMessage({type: 'routerPush', payload: 'main'});
        if (router?.history?.current?.name !== 'main') {
          await router.push({
            name: 'main'
          });
        }
        this._vm.$broadcastChannel.postMessage({type: 'routerReload'});
        setTimeout(() => commit('SET_PRELOADER_APP', false), 1000);
      } catch (error) {
        commit('SET_SNACK', {text: error.data || error, type: 'error'});
        commit('SET_PRELOADER_APP', false);
        throw error;
      }
    },
  },
  getters: {},
  mutations: {
    SET_LOGIN(state, data) {
      Cookies.set('RTT', data.token, data.staySignedIn || state.user.staySignedIn ? {expires: 365} : {});
      localStorage.setItem('RRTT', data.refresh_token);
      if (data.staySignedIn || state.user.staySignedIn) {
        Cookies.set('staySignedIn', true, {expires: 365});
        state.staySignedIn = true;
      }
      state.token = data.token;
      state.refreshToken = data.refresh_token;
    },
    SET_LOGOUT(state) {
      state.token = null;
      state.refreshToken = null;
      state.user = {};
      Cookies.remove('RTT');
      Cookies.remove('staySignedIn');
      Cookies.remove('RTTA');
      Cookies.remove('adminName');
      state.tokenAdmin = null;
      state.refreshTokenAdmin = null;
      localStorage.clear();
    },
    SET_ADMIN_CREDENTIALS(state, data) {
      Cookies.set('RTTA', data.token, {expires: 365});
      Cookies.set('adminName', data.admin_name, {expires: 365});
      localStorage.setItem('RRTTA', data.refresh_token);
      state.tokenAdmin = data.token;
      state.refreshTokenAdmin = data.refresh_token;
      state.adminName = data.admin_name;
    },
    SET_USER(state, data) {
      state.user = data;
    },
    DELETE_ADMIN_CREDENTIALS(state) {
      state.tokenAdmin = null;
      state.refreshTokenAdmin = null;
      state.adminName = null;
      Cookies.remove('RTTA');
      Cookies.remove('adminName');
      localStorage.removeItem('RRTTA');
    },
  }
};