import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app';
import user from './modules/user';
import campaigns from './modules/campaigns';
import landingPages from './modules/landingPages';
import customCodes from './modules/customCodes';
import deploys from './modules/deploys';
import customPages from './modules/customPages';
import sites from './modules/sites';
import translations from './modules/translations';
import tags from './modules/tags';
import admin from './modules/admin';
import tuneRevenue from './modules/tuneRevenue';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    campaigns,
    landingPages,
    customCodes,
    deploys,
    customPages,
    sites,
    translations,
    tags,
    admin,
    tuneRevenue,
  },
  state: {},
  mutations: {},
  actions: {},
})
