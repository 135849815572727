import { BroadcastChannel, createLeaderElection } from 'broadcast-channel';
import Cookies from 'js-cookie';
import Vue from 'vue';
import store from '@/store';
import {router} from '@/router';
import $axios from '@/plugins/axios';

let broadcastChannel = {
  isDuplicateLider: false,
  duplicateLiders: [],
};

const handlerBroadcastChannel = async msg => {
  switch (msg.type) {
    case 'info':
      if (msg.payload) console.log('handlerBroadcastChannelInfo', msg.payload);
      break;
    case 'login':
      store.commit('SET_LOGIN', msg.payload);
      break;
    case 'loginAsUser':
      store.commit('SET_ADMIN_CREDENTIALS', msg.payload);
      break;
    case 'logout':
      if (Cookies.remove('RTT') || store.state.user.token) await store.dispatch('logout');
      break;
    case 'deleteAdminCredentials':
      if (Cookies.remove('RTTA') || store.state.user.tokenAdmin) await store.commit('DELETE_ADMIN_CREDENTIALS');
      break;
    case 'setUser':
      store.commit('setUser', msg.payload);
      break;
    case 'routerPush':
      await router.push({
        name: msg.payload
      });
      break;
    case 'routerReload':
      await router.go();
      break;
    case 'setIsAlreadyFetchingAccessToken':
      $axios.setIsAlreadyFetchingAccessToken(msg.payload);
      break;
    case 'setSubscribers':
      $axios.setSubscribers(msg.payload);
      break;
    case 'onAccessTokenFetched':
      $axios.onAccessTokenFetched(msg.payload);
      break;
    case 'refreshTokenTabs':
      await $axios.refreshTokenTabs();
      break;
    case 'electorOnduplicate':
      await electorOnduplicate(msg.payload);
      break;
    default:
      if (msg.payload) console.log('handlerBroadcastChannelInfo', msg.payload);
  }
};

broadcastChannel.channel = new BroadcastChannel('ScaleClub-broadcast-channel');
broadcastChannel.channel.addEventListener('message', handlerBroadcastChannel);
Vue.prototype.$broadcastChannel = broadcastChannel.channel;

broadcastChannel.elector = createLeaderElection(broadcastChannel.channel);
broadcastChannel.elector.awaitLeadership()
  .then(()=> {
    if (broadcastChannel.elector.isLeader) {
      store.commit('SET_LEADER_TAB', true);
    }
  })

broadcastChannel.elector.onduplicate = async () => {
  broadcastChannel.isDuplicateLider = true;
  broadcastChannel.duplicateLiders.push(broadcastChannel.elector.token);
  store.commit('SET_LEADER_TAB', false);
  await broadcastChannel.channel.postMessage({type: 'electorOnduplicate', payload: broadcastChannel.elector.token});
}

async function electorOnduplicate(token) {
  broadcastChannel.duplicateLiders.push(token);
  store.commit('SET_LEADER_TAB', broadcastChannel.duplicateLiders.sort()[0] === broadcastChannel.elector.token);
}

broadcastChannel.checkUpdate = function () {
};

export default broadcastChannel;
