export default axios => ({
  getCampaigns(params) {
    return axios.get('/campaigns', {params})
  },
  reSyncCampaigns() {
    return axios.post('/campaigns/resync')
  },
  toggleSyncCampaigns(data) {
    return axios.post(`/campaigns/${data.id}/toggle-sync`)
  },
  forceSyncCampaigns(data) {
    return axios.post(`/force-sync/${data.id}`)
  },
  addSpreadsheetCampaigns(data) {
    return axios.post(`/spreadsheet/${data.id}`)
  },
  deleteSpreadsheetCampaigns(data) {
    return axios.delete(`/spreadsheets/${data.id}`)
  },
  saveSettingsCampaigns(data) {
    return axios.post(`/campaigns/${data.id}/settings`, data)
  },
});