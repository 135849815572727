export default {
  state: {
  },
  actions: {
    async getDeploys (state, params) {
      return await this._vm.$api.deploys.getDeploys(params)
    },
    async getDeploy (state, params) {
      return await this._vm.$api.deploys.getDeploy(params)
    },
    async postDeploy (state, params) {
      return await this._vm.$api.deploys.postDeploy(params)
    },
    async putDeploy (state, params) {
      return await this._vm.$api.deploys.putDeploy(params)
    },
    async deleteDeploy (state, params) {
      return await this._vm.$api.deploys.deleteDeploy(params)
    },
    async pushToGit (state, params) {
      return await this._vm.$api.deploys.pushToGit(params)
    },
    async postCloneDeploy (state, params) {
      return await this._vm.$api.deploys.postCloneDeploy(params)
    },
    async postRemoveFromGitDeploy (state, params) {
      return await this._vm.$api.deploys.postRemoveFromGitDeploy(params)
    },
  },
  mutations: {
  },
  getters: {
  },
};